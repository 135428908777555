@font-face {
    font-family: 'Tilt Neon';
    src: url("TiltNeon-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Roboto';
    src: url("Roboto-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Roboto';
    src: url("Roboto-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Roboto';
    src: url("Roboto-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Roboto';
    src: url("Roboto-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Roboto';
    src: url("Roboto-Light.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Montserrat';
    src: url("Montserrat-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Montserrat';
    src: url("Montserrat-SemiBold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Montserrat';
    src: url("Montserrat-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Montserrat';
    src: url("Montserrat-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Montserrat';
    src: url("Montserrat-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Montserrat';
    src: url("Montserrat-Thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: block;
}