.history-home-tasks.main-container {
    margin-top: 20px;
}

.task-status p {
    margin: auto;
    width: fit-content;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 8px;
    border: 1px solid;
    border-radius: 17px;
}

.task-status .task-state-completed {
    background: #b8dab8;
    border-color: #33ab33;
    color: #226d22;
}

.task-status .task-state-not-completed {
    background: #aabfdf;
    border-color: #5e7fad;
    color: #1e5bb4;
}

.task-status .task-state-in-progress {
    background: #f4e0a4;
    border-color: #e6b111;
    color: #b48a0b;
}

.page-header .task-score {
    font-size: 12px;
}

.task-content-wrapper {
    display: flex;
    column-gap: 50px;
}

.task-content {
    width: 60%;
}

.task-content.task-content-full-width {
    width: 100%;
}

.task-content .task-content-file svg {
    width: 17px;
    height: 17px;
    margin-right: 7px;
}

.task-content .task-content-file svg path,
.task-content .task-content-file svg polyline,
.task-content .task-content-file svg line {
    stroke: #fff;
    transition: all 0.25s linear;
}

.task-content .task-content-file:hover svg path,
.task-content .task-content-file:hover svg polyline,
.task-content .task-content-file:hover svg line {
    stroke: white;
}

.task-history {
    margin-top: 30px;
}

.task-answer-content-file {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.task-answer-content-file svg {
    width: 14px;
    height: 11px;
}

.task-answer-content-file svg path,
.task-answer-content-file svg polyline,
.task-answer-content-file svg line {
    stroke: white;
}

.task-answer-content-link {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.task-answer-content-link svg {
    width: 14px;
    height: 11px;
}

.task-answer-content-lesson {
    font-size: 12px;
}

.task-answer {
    display: flex;
    align-items: center;
    margin: 10px 0 20px;
    background: #fff;
    /*border: 1px solid #e7e9ec;*/
    padding: 20px;
}

.answer-count {
    background: var(--yellow-color);
    color: black;
    border-radius: 50%;
    font-weight: 600;
    font-size: 18px;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 2px;
}

.task-answer-content {
    width: calc(100% - 25px);
    padding-left: 20px;
    font-weight: 500;
    position: relative;
}

.task-answer-content-lesson a {
    text-transform: capitalize;
}

.task-answer-content-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    margin-top: 10px;
}

.task-answer-content-point {
    display: flex;
}

.point-title {
    margin-right: 10px;
    min-width: 75px;
}

.task-answer-content-title.trainer-answer {
    margin-top: 20px;
}

.task-answer-content-trainer-answer q::before,
.task-answer-content-trainer-answer q::after{
    color: white;
    font-weight: 700;
    margin: 5px;
}

.task-answer-finish {
    margin-top: 10px;
    color: #226d22;
    display: flex;
    align-items: center;
}

.task-answer-finish svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.task-answer-finish svg path {
    fill: #226d22;
}

.task-answer-finish .task-score {
    margin-left: 10px;
    font-weight: 600;
    white-space: nowrap;
}

.task-answer-finish .task-update-date {
    margin-left: 10px;
}

.task-answer-form {
    background: #fff;
    box-shadow: 0 5px 15px #0000003d;
    border-radius: 11px;
    padding: 25px;
    width: 40%;
    height: fit-content;
}

.page-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.back-btn {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
}

.back-btn svg {
    width: 20px;
    height: 18px;
    transition: all 0.25s linear;
}

.back-btn:hover svg {
    margin: 0 6px 0 -6px;
}

.task-status {
    display: flex;
    column-gap: 20px;
}

.task-status-score {
    display: flex;
}

.task-status-score .score {
    border: none;
    padding: 0 5px 0 0;
    font-size: 20px;
    font-weight: 400;
}

.task-status-score .task-price {
    border: none;
    padding: 0;
    font-size: 20px;
}

.task-update-date {
    position: absolute;
    top: -2px;
    right: -5px;
    font-size: 12px;
    font-weight: 600;
}

.task-content-wrapper .task-form button {
    width: 100%;
    padding: 8px 10px;
}

.drop-zone {
    max-width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: var(--grey-text);
    border: 2px dashed var(--grey-text);
    border-radius: 7px;
    cursor: pointer;
    padding: 0;
    position: relative;
    margin-bottom: 15px;
}

.drop-zone--over {
    border-style: solid;
}

.drop-zone__input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


.drop-zone__remove-file {
    z-index: 2;
    padding: 3px;
    margin-left: 5px;
    margin-bottom: 4px;
}

.drop-zone__remove-file svg {
    width: 16px;
    height: 15px;
}
  
.tasks-filters {
    display: flex;
    margin-left: auto;
    column-gap: 15px;
}
  
@media screen and (max-width: 1024px) {
    .task-content-wrapper {
        flex-direction: column;
        row-gap: 20px;
    }

    .history-home-tasks {
        max-width: unset;
        padding: 0 22px;
    }

    .task-content {
        width: 100%;
    }

    .task-answer-form {
        width: 100%;
        max-width: 400px;
    }
}

@media screen and (max-width: 767px) {
    .task-answer-content {
        display: flex;
        flex-direction: column;
    }

    .task-update-date {
        position: static;
        order: 9;
        margin-top: 10px;
    }

    .task-answer-content-message {
        flex-direction: column;
    }

    .page-top-bar {
        flex-direction: column;
        align-items: flex-start;
    }

    .task-status p {
        margin: auto 0;
    }
}
  