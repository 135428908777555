.answer {

  margin-left: 15px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 5px;

  &.correct{
     background: #80e24e;
  }
  &.incorrect{
    background: #ffa5a5;
  }

}