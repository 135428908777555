.button {
  cursor: pointer;
  text-decoration: none;
  //width: fit-content;

  &.button-primary {
    align-items: center;
    background: var(--yellow-color);
    border: 1px solid var(--button-primary-border-color);
    //border-radius: var(--button-primary-border-radius);
    box-shadow: var(--button-primary-box-shadow);
    color: var(--button-primary-text-color);
    display: flex;
    font-family: var(--font-family-base);
    font-size: var(--font-size-button-primary);
    font-style: normal;
    font-weight: 800;
    justify-content: center;
    line-height: 24px;
    padding: 7px 23px;
    text-transform: uppercase;
    transform: scale(1);


    &:hover{
      background: transparent;
      color: var(--yellow-color);
    }
  }
}