.error-text, .thanks-text {
    display: none;
    font-size: 20px;
}

.error-text svg, .thanks-text svg {
    width: 30px;
    height: 30px;
}

.thanks-text svg path {
    fill: #226d22;
}

.error-text svg path {
    fill: white;
}

.modal.show .modal-dialog {
    top: 50%;
    margin-top: 0;
    transform: translateY(-50%);

    &.quiz {
        max-width: 60%;
        .modal-body{
            max-height: 80vh;
            overflow-y: auto;
        }
    }
}


@media screen and (max-width: 767px) {
    .modal.show .modal-dialog {
        margin: 0 20px;
    }

    .modal-title {
        font-size: 18px;
    }

    .error-text, .thanks-text {
        font-size: 16px;
    }
}
