.course-detail-template {
    
}

.banner {
    position: relative;
    height: 500px;
    background-position: center;
    background-size: cover;
    /*background-color: var(--background-banner);*/
    max-width: 100vw;
    overflow: hidden;
}

.banner::after {
    content: "";
    /*opacity: .5;*/
    /*background-color: #fff;*/
    /*background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0) 4%, #000 100%);*/
    /*background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 4%, #000 100%);*/
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.banner-content {
    color: white;
    position: absolute; 
    z-index: 10; 
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    padding: 40px;
}

.banner-course-type {
    max-width: 500px;
    margin: auto;
}

.banner-heading {
    color: #fff;
    max-width: 500px;
    margin: auto;
    position: relative;
}

.banner-text {
    padding: 40px 0 10px;
    max-width: 500px;
    margin: auto;
}

.banner-image {
    position: absolute; 
    z-index: 10; 
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    height: 100%;
}

.banner-image svg {
    width: 100%;
    height: 100%;
}

.banner-trainer {
    max-width: 500px;
    margin: auto;
}

.banner-trainer span{
    text-transform: uppercase;
    font-weight: 600;
}

.course-lessons-container {
    width: 60%; 
}

.course-lessons-list-item {
    padding: 5px; 
    background: #fff;  
    margin-bottom: 10px;
}

.course-lessons-list-item.disabled {
    background: grey;
}

.course-lessons-header {
    display: flex;
}

.course-lessons-list {
    margin-top: 20px;
}

.course-lessons-list-item {
    background-color:#fff;
    border: 1px solid #e7e9ec;
    background-size: 0% 100%;
    padding: 14px 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    background-repeat:no-repeat;
    background-image:linear-gradient(90deg, var(--blue-color) 0%, rgba(255,255,255,1) 66%);
    transition:background-size .5s, background-color .5s, color .3s;
}
.course-lessons-list-item.disabled {
    opacity: .5;
    color: white;
    cursor: not-allowed;
}
.course-lessons-list-item.disabled:hover{
     background-size: 0;
}


.course-lessons-list-item:hover {
    background-size: 100% 100%; 
    color: #fff;
}

.course-lessons-list-item p{
    text-decoration: none;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    margin-right: 20px;
    display: block;
}

.course-lessons-list-item svg {
    width: 13px;
    height: 13px;
    margin: 0 4px;
}

.course-wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    position: relative;
}

.course-short-details {
    width: 30%;
    background: #fff;
    /*box-shadow: 0 5px 15px #0000003d;*/
    border-radius: 11px;
    padding: 25px 25px 10px;
    height: fit-content;
    position: sticky;
    right: 0;
    top: 20px;
}

.course-short-details .request-review-btn {
    margin: 0 auto 20px;
}

.course-short-details-image {
    margin-bottom: 20px;
}

.button.course-short-details-link {
    margin: 0 auto 20px;
    padding: 5px 25px;
}

.course-short-details-image svg {
    width: 100%;
    height: auto;
}

.course-short-details-title {
    margin-bottom: 10px;
    font-weight: 700;
}

.course-short-details-points-item {
    margin-bottom: 15px;
}

.course-short-details-points-item svg {
    width: 18px;
    height: 20px;
    margin-right: 4px;
    margin-bottom: 2px;
}

.banner-container {
    position: relative;
    height: 100%;
}

@media screen and (max-width: 1024px) {
    .banner-image, .banner-content {
        position: static;
        transform: none;
        padding: 0;
    }

    .banner-content {
        width: 60%;
    }

    .banner-image {
        width: 30%;
        height: fit-content;
    }

    .banner-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .banner {
        height: auto;
    }

    .course-wrapper {
        flex-direction: column-reverse;
        padding: 0 22px;
        width: 100%;
        max-width: unset;
    }

    .course-lessons-container {
        width: 100%;
    }

    .course-short-details {
        width: 100%;
        max-width: 350px;
        margin: 0 auto 25px;
        position: static;
    }

    .course-lessons-header {
        flex-direction: column;
    }

    .course-lessons-header .course-progress {
        margin: 0;
        width: 100%;
    }

    .course-lessons-header .course-progress .progress-line {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .banner-image, .banner-content {
        width: 100%;
    }

    .banner-container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .banner {
        height: auto;
    }

    .banner-image svg {
        margin: auto;
        display: block;
    }

    .banner-content {
        margin-bottom: 40px;
    }

    .banner-heading {
        font-size: 24px;
        margin-top: 5px;
    }

    .banner-text {
        padding: 18px 0 10px;
    }
}

.lesson-item:hover {
    font-weight: bold;
    border: 1px solid #fffa3b;
}