.lesson-detail-template {
    display: flex;
    height: 100%;
    min-height: calc(100vh - 66px);
}

.lesson-detail-navigation {
    width: 27%;
    background: #fff;
    border: 1px solid #e7e9ec;
    //padding: 20px 10px;
    max-width: 370px;
}

.nav-course-title {
    text-decoration: none;
    color: #2b3035;
    font-size: 18px;
    position: relative;
    font-weight: 600;
    width: fit-content;
    display: block;
    margin-bottom: 10px;
}

.nav-course-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:  var(--yellow-color);;
}

.lesson-detail-navigation .request-review-btn {
    margin: 20px auto 0;
}

.lesson-detail-container {
    width: 72%;
    padding: 20px;
    margin: 0 auto;
    max-width: 1000px;
    transition: all 0.25s linear;
}

.lesson-detail-navigation .back-btn {
    display: block;
}

.lesson-detail-navigation .back-btn svg {
    margin-bottom: 1px;
}

.nav-header {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid hsla(0,0%,100%,.36);
}

.nav-header .course-progress {
    margin: 0;
}

.nav-header .progress-line {
    width: 100%;
}

.nav-header .course-trainer {
    margin-top: 10px;
}

.lesson-detail-lessons-heading {
    font-size: 16px;
    font-weight: 700;
}

.lesson-detail-lesson-link {
    color: #2b3035;
    font-size: 14px;
    font-weight: 600;

    .name-lesson {
        padding: 5px 10px;
        cursor: pointer;

        &.active, &:hover {
            background: var(--active-bg);
        }
    }

    .sublist-lessons {
        display: none;


        &.open {
            display: block;
        }

        p {
            margin-bottom: 7px;
            padding: 5px 10px;
            padding-left: 25px;
            position: relative;

            &.active, &:hover {
                background: var(--active-bg);

                &:before {
                    content: "";
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    top: 7px;
                    left: 4px;
                    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M15.1997 10.4919L13.2297 8.52188L10.0197 5.31188C9.33969 4.64188 8.17969 5.12188 8.17969 6.08188V12.3119V17.9219C8.17969 18.8819 9.33969 19.3619 10.0197 18.6819L15.1997 13.5019C16.0297 12.6819 16.0297 11.3219 15.1997 10.4919Z' fill='%23a31616'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");

                }
            }
        }

        a {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }

    .lesson-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


}


.lesson-detail-lesson-link.disabled {
    opacity: .5;
}

.lesson-detail-lesson-link.disabled:hover {
    color: black;
    position: relative;
    background: none;
    cursor: not-allowed;
}

.lesson-detail-lesson-link svg {
    width: 14px;
    height: 13px;
    margin: 0 2px 2px;
}



.lesson-detail-lesson-link.active::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 7px;
    left: 4px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M15.1997 10.4919L13.2297 8.52188L10.0197 5.31188C9.33969 4.64188 8.17969 5.12188 8.17969 6.08188V12.3119V17.9219C8.17969 18.8819 9.33969 19.3619 10.0197 18.6819L15.1997 13.5019C16.0297 12.6819 16.0297 11.3219 15.1997 10.4919Z' fill='%23a31616'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.lesson-detail-container .lesson-title {
    text-decoration: none;
    color: #2b3035;
    font-size: 30px;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    width: fit-content;
}

.lesson-detail-container .lesson-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:  var(--yellow-color);
}

.lesson-detail-container .lesson-conent {
    margin-top: 30px;
}

.lesson-detail-container .lesson-video {
    margin-top: 20px;
}

.lesson-detail-container .lesson-video iframe {
    margin: auto;
    display: block;
    width: 100% !important;
    height: 500px !important;
}



.lesson-detail-container .lesson-extra-info {
    margin-top: 15px;
}

.lesson-extra-info-item {
    display: flex;
}

.lesson-extra-info-item svg {
    width: 13px;
    height: 14px;
    margin-bottom: 4px;
}

.lesson-extra-info-links svg path {
    fill: #2b3035;
}

.extra-info-link {
    text-transform: capitalize;
    margin-left: 5px;
    text-decoration: underline;
}

.extra-info-heding {
    font-size: 15px;
    font-weight: 600;
    margin-right: 5px;
}

.lesson-detail-top-bar {
    display: flex;
    justify-content: space-between;
}

.lesson-detail-lessons-count {
    font-size: 14px;
    font-weight: 600;
}

.lesson-home-tasks {
    margin: 40px 0;
}

.task-content-link {
    display: block;
    font-weight: 600;
    text-decoration: underline;
}

.task-content-buttons {
    display: flex;
    column-gap: 20px;
    margin-top: 20px;
    align-items: center;
}

.task-content-buttons .task-content-file {
    margin: 0;
}

.sidebar {
    transition: all 0.25s linear;
    width: 370px;
    min-width: unset;
    position: fixed;
    height: 100%;
    z-index: 9;
    transform: translateX(0);
}

.sidebar.close {
    box-shadow: 0 5px 15px #0000003d;
    transform: translateX(-341px);
}

.sidebar .sidebar-content {
    transition: all 0.25s linear;
    opacity: 1;
}

.sidebar.close .sidebar-content {
    opacity: 0;
}

.page-with-sidebar .lesson-detail-container {
    width: calc(100% - 370px);
    transition: all 0.25s linear;
    transform: translateX(190px);
}

.page-with-sidebar.close .lesson-detail-container {
    width: 100%;
    max-width: 1300px;
    transform: translateX(0);
}

.toggle-sidebar {
    position: absolute;
    width: 40px;
    height: 40px;
    right: -18px;
    top: 20px;
    padding: 5px;
    background: #f9f9f9;
    border-radius: 50%;
    box-shadow: 0 5px 15px #0000003d;
    transition: all 0.25s linear;
    cursor: pointer;
    z-index: 2;
}

.sidebar .toggle-sidebar svg {
    transform: rotate(180deg);
}

.sidebar.close .toggle-sidebar svg {
    transform: rotate(0);
}

.toggle-sidebar svg {
    width: 30px;
    height: 30px;
}

@media screen and (max-width: 1024px) {
    .sidebar.load {
        transition: none;
        transform: translateX(-336px);
    }

    .sidebar.load .toggle-sidebar svg {
        transform: rotate(0);
    }

    .sidebar.load .sidebar-content {
        opacity: 0;
    }

    .page-with-sidebar .lesson-detail-container {
        padding-left: 70px;
        width: 100%;
        transform: translateX(0);
    }

    .sidebar {
        box-shadow: 0 5px 15px #0000003d;
        max-width: calc(100% - 30px);
    }
}

@media screen and (max-width: 767px) {
    .lesson-detail-top-bar {
        flex-direction: column;
    }

    .lesson-detail-lessons-count {
        margin-bottom: 10px;
    }

    .page-with-sidebar .lesson-detail-container {
        padding-left: 48px;
    }

    .lesson-detail-container .lesson-title {
        font-size: 20px;
    }

    .lesson-detail-container .lesson-conent {
        margin-top: 15px;
    }

    .lesson-detail-container .lesson-video iframe {
        max-width: 100%;
        height: auto;
    }

    .lesson-home-tasks {
        margin: 20px 0;
    }

    .accordion-item .accordion-header .accordion-button {
        padding: 10px 30px 10px 10px;
        font-size: 14px;
    }

    .accordion-header svg {
        width: 17px;
        height: 17px;
    }

    .accordion .accordion-button::after {
        right: 6px;
        background-size: 19px;
    }

    .task-content-buttons {
        flex-direction: column;
        row-gap: 12px;
    }
}